import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission'
import vant from 'vant'
import { Toast } from "vant"
import 'vant/lib/index.css';
import httpRequest from '@/utils/request'
//手机端开发者调试start
// var script = document.createElement('script')
// // script.src = 'https://cdn.bootcss.com/eruda/1.5.2/eruda.min.js'
// script.src = '//cdn.jsdelivr.net/npm/eruda'
// script.async =true
// document.getElementsByTagName('head')[0].appendChild(script)
// script.onload = function () { window.eruda.init() }
//手机端开发者调试end
Vue.use(vant)

Vue.config.productionTip = false
Vue.prototype.$http = httpRequest
Vue.prototype.Toast = Toast

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
